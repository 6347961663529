<template>
  <div class="index" :style="{ minHeight: minHeight + 'px' }">
    <van-sticky>
      <van-nav-bar :placeholder="true">
        <template slot="title">
          <div class="nav">
            <van-icon
              name="arrow-left"
              style="color: #333333"
              size="0.6rem"
              @click="backBtn"
            />
            <div class="card_text">
              <span class="card_name">{{ maskChineseName(form.name) }}</span
              >&nbsp;{{ form.sex === 1 ? "男" : "女"
              }}<span v-if="form.age !== -1">/{{ form.age + "岁" }}</span
              ><span
                v-if="
                  form.hasOwnProperty('patientNumber') &&
                    form.patientNumber !== ''
                "
                >/{{ form.patientNumber }}</span
              >

              <img
                src="../../assets/medical/edit.png"
                style="width: 15px;height: 15px;margin-left: 10px"
                @click="editItem"
                alt=""
              />
            </div>
          </div>
        </template>
      </van-nav-bar>
    </van-sticky>

    <div
      class="list"
      :style="{ minHeight: minHeight - 70 + 'px' }"
      v-if="list.length !== 0"
    >
      <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="getList"
        >
      <van-collapse
        accordion
        class="dym_collapse"
        v-model="activeName"
        v-for="item in list"
        :key="item.id"
          @change="change1(item.id)"
      >
        <van-collapse-item :name="item.id">
          <div slot="title">
            <div class="swipe_title">
              {{ item.chineseName }}
              <van-icon
                v-if="item.id == activeName"
                name="arrow-up"
                size="14px"
                style="margin-left:3px;"
              />
              <van-icon
                v-if="item.id !== activeName"
                name="arrow-down"
                size="14px"
                style="margin-left:3px;"
              />
            </div>
            <div class=" swipe_title_sub ">
              <span>最近使用时间： {{ dateType(item.createdTime, 0) }}</span>
              <span
                style="margin-right:-20px;font-size:14px;color:rgba(25, 155, 251, 1);"
                v-if="item.id == activeName"
                @click.stop="goNewBtn(item)"
              >
                <van-icon
                  color="rgba(25, 155, 251, 1)"
                  name="add-o"
                  size="14px"
                  style="margin-right:3px;"
                />新增</span
              >
            </div>
          </div>
            <div>
                <div
                  v-for="detail in detailList"
                  :key="detail.id"
                  class="detail"
                   @click="routeBtn(detail)"
                
                >
                  <div>结果：{{ detail.result.DisplayResults }}</div>
                  <div class="detail_bottom">
                    <span class="swipe_title_sub">{{
                      dateType(detail.createdTime, 0)
                    }}</span>

                    <van-icon
                      color="#CAC9C9"
                      name="ellipsis"
                      @click.stop="deleteItem(detail)"
                      size="20px"
                      style="margin-right:3px"
                    />
                  </div>
                </div>
              </div>
        </van-collapse-item>
      </van-collapse>
      </van-list>
    </div>

    <div v-else>
      <van-empty description="暂无内容" />
    </div>

    <medical-form
      :visible="formShow"
      :editData="form"
      :member-id="memberId"
      :type="'edit'"
      @close="hideStudent"
    >
    </medical-form>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import medicalForm from "@/components/medicalForm.vue";
import {
  delMedicalLog_time,
  getMedicalLog_patientInfo,
  getMedicalLog_time,
  getGroupRecord
} from "@/service/api";
import moment from "moment";
import { postDataBrowse } from "@/service/api";
import { getAdmins } from "@/utils/adminDate";
export default {
  name: "patientLog",
  components: {
    medicalForm,
  },
  data() {
    return {
      formShow: false,
      form: {},
      list: [],
      memberId: "",
      minHeight: window.innerHeight,
      menuVisible: false,
      menuPosition: {
        top: 0,
      },
      selectedIndex: null,
      pageNo: 1,
      pageSize: 10,
      activeName: null,
      formulaCode:"",
      patientId:"",
      detailList:[],
       loading: false,
      finished: false,
      addBtn:false,
        beforeUrl: null,
    };
  },
   beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeUrl = from.path;
    });
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
    activated() {

    this.$nextTick(() => {
      //获取上级路由
       if(this.beforeUrl=="/medicalHome"){
           this.pageNo=1
           this.list=[]
           this.getList()
       }
       else{
           this.getDetail();
       }
       
    });




      console.log("执行");
      
      // this.getDetail();
    
     
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    // this.getList();
    console.log("挂载");
     
  },
  created(){
     console.log("xxx");
  },
  methods: {
    handleClickOutside(event) {
      if (event.target.className.includes("van-icon van-icon-ellipsis")) {
        console.log(1);
      } else {
        this.selectedIndex = "随机数";
        this.menuVisible = false;
      }
    },

    maskChineseName(name) {
      if (!name || typeof name !== "string") {
        return "";
      }

      // 检查姓名长度
      const length = name.length;

      if (length <= 1) {
        // 如果姓名长度小于等于1，直接返回原名
        return name;
      } else if (length === 2) {
        // 如果姓名长度为2，只替换最后一个字符
        return name[0] + "*";
      } else {
        // 如果姓名长度大于2，替换中间字符
        const firstChar = name[0];
        const lastChar = name[length - 1];
        const middleAsterisks = "*".repeat(length - 2);

        return firstChar + middleAsterisks + lastChar;
      }
    },

    dateType(date) {
      return moment(date).format("MM月DD日 HH:mm");
    },
    async getList() {
      const form = JSON.parse(this.$store.state.editForm);
      const info = await getMedicalLog_patientInfo(form.id);
      this.form = info.data;
      this.form.channel = form.channel;
      this.form.memberId = form.memberId;
      this.patientId=this.form.id
      let data = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        patientId: this.form.id,
      };
      const res = await getMedicalLog_time(data);
      if (res.code === 0) {
          this.loading = false;
          let item = res.data;
          this.list = this.list.concat(item);
          if (this.list.length >= res.count) {
            this.finished = true;
          }
          if (this.list.length < res.count) {
            this.finished = false;
            this.pageNo += 1;
          }
          // this.activeName = this.list[0].id;
          // this.formulaCode = this.list[0].code;
          
          // this.getDetail();
        }
    },
    hideStudent() {
      // this.getList();
      this.formShow = false;
    },
    backBtn() {
      window.history.back();
    },
    toggleMenu(index, event) {
      if (this.selectedIndex === index && this.menuVisible) {
        // 如果当前菜单已经打开，则关闭它
        this.menuVisible = false;
        this.selectedIndex = null;
      } else {
        // 否则，打开菜单并设置位置
        this.selectedIndex = index;
        this.menuVisible = true;
        this.$nextTick(() => {
          const rect = event.target.getBoundingClientRect();
          this.menuPosition.top = rect.bottom + window.scrollY;
        });
      }
    },
    editItem() {
      this.formShow = true;
    },
    async deleteItem(row) {
      Dialog.confirm({
        title: "是否确认删除？",
        confirmButtonText: "是",
        cancelButtonText: "否",
      })
        .then(async () => {
          const del = await delMedicalLog_time(row.id);
          if (del.statuscode === 200) {
            Toast.success("删除成功");
            this.getDetail();
          } else {
            Toast.fail(del.message);
          }
        })
        .catch(() => {
          // on cancel
        });

      this.menuVisible = false;
      this.selectedIndex = null;
    },
    routeBtn(item) {
      if (this.menuVisible === true) {
        this.menuVisible = false;
        this.selectedIndex = null;
        return;
      }
      console.log(this.form);
      this.$router.push({
        path: "/formula/" + this.formulaCode,
        query: {
          logId: item.id,
          memberId: this.form.memberId,
          channel: this.form.channel,
          id: this.activeName,
          type: "h5",
          location: "YXGS_RECORD_LIST",
        },
      });
      this.addBtn=false
      this.addClickRecord(item);
    },
        // 新增跳转
    goNewBtn() {
      if (this.menuVisible === true) {
        this.menuVisible = false;
        this.selectedIndex = null;
        return;
      }
      this.$router.push({
        path: "/formula/" + this.formulaCode,
        query: {
          id: this.activeName,
          memberId: this.$store.state.memberIdDocument,
          channel: this.$route.query.channel,
          type: "h5",
          location: "YXGS_RECORD_ADD_LIST",
        },
      });
      this.addBtn=true
    },
    async addClickRecord(item) {
      //像接口头部传用户信息
      let arrays = await getAdmins(this.form.memberId, this.form.channel);
      //点击埋点
      const data = {
        type: "h5", //index表示在首页列表中点击，mix_index表示在专题列表中点击
        contentId: item.formulaId, //为vod时，根据contentId增加浏览量
        contentType: "YXGS_DWHS",
        location: "YXGS_RECORD_LIST",
        channel: this.form.channel,
        os: arrays.os,
        memberId: this.form.memberId,
      };
      console.log(data);
      await postDataBrowse(data);
    },
    async getDetail() {
      let data = {
        pageNo: 1,
        pageSize: 99999,
        formulaCode: this.formulaCode,
        patientId:this. patientId

      };
      const res = await getGroupRecord(data);
      if (res.code == 0) {
        this.detailList = res.data;
        console.log( this.detailList);
        
        if (this.detailList.length == 0) {
          this.pageNo = 1;
          this.list = [];
          this.getList();
        }
      }
    },
     change1(id) {
      this.list.forEach((item) => {
        if (item.id == id) {
          this.formulaCode = item.code;
          this.getDetail();
        }
      });
    },
  },
 
};
</script>
<style scoped lang="scss">
::v-deep .van-nav-bar__title {
  margin: 0 10px !important;
}
::v-deep .van-nav-bar .van-icon {
  color: #000000;
  margin-right: 10px !important;
}
::v-deep .van-nav-bar__left {
  padding: 0 !important;
}
.edit {
  ::v-deep .van-icon {
    color: #999999 !important;
  }
}
.index {
  padding-bottom: 10px;
  background: #f6f6f6;
  height: auto;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
  .nav {
    display: flex;
    align-items: center;
    .card_text {
      display: flex;
      align-items: center;
      //margin-top: 5px;
      color: #999999;
      font-size: 13px;
      .card_name {
        font-size: 16px;
        color: #333333;
      }
    }
  }
  .list {
    margin: 10px 0px;
    background: #f6f6f6;
    border-radius: 6px;

    .card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 15px;
      border-bottom: #f6f6f6 1px solid;
    }
    .swipe_title_sub {
      margin-top: 5px;
      color: #999999;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
    }
  }
}

.popup-menu {
  position: absolute;
  background: white;
  border: 1px solid #f1f1f1;
  padding: 8px;
  right: 5px;
  font-size: 13px;
  z-index: 1000; /* 确保下拉框在最前面 */
}
.list-item {
  position: relative; /* 使下拉框相对于列表项定位 */
  margin-bottom: 20px; /* 添加间距以避免重叠 */
}
.DisplayResults {
  margin-top: 5px;
  font-size: 13px;
  color: #2063aa;
  font-weight: 600;
}

.dym_collapse {
  ::v-deep .van-cell {
    background-color: rgb(246, 246, 246);
    border-bottom: 0.5px solid rgb(239, 239, 239);
  }
  ::v-deep .van-collapse-item__content {
    background-color: white;
  }
}
.detail {
  font-size: 14px;
  color: black;
  background: white;
  padding: 10px 0px;
}
.detail:not(:last-child) {
  border-bottom: 1px solid #eeeeee;
}
.detail_bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::v-deep .van-cell__right-icon {
  opacity: 0;
}
.swipe_title {
  font-size: 16px;
  display: -webkit-box;
  /* 表示几行后超出隐藏 */
  -webkit-line-clamp: 2;
  /* 超出隐藏 */
  overflow: hidden;
  /*超出显示为省略号*/
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  /*强制英文单词自动换行*/
  word-break: break-all;
  color: black;
}
</style>
